import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      background: theme.palette.resources.backgroundLight,
      height: '100%',
    },
  },
  formContainer: {
    marginTop: theme.spacing(-30),
    height: theme.spacing(110),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    background: theme.palette.white,
    boxShadow: '0px 20px 70px #33333314',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: theme.spacing(80),
      width: '100%',
      // padding:theme.spacing(2),
      // height:'100%',
      marginTop: theme.spacing(2),
    },
  },
  heading: {
    width: '80%',
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(5),
    color: theme.palette.home.textBlackDark,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
    // opacity:.06,
    // marginTop: theme.spacing(2),
    background: theme.palette.contact.blueLight,
    [theme.breakpoints.down('md')]: {
      // margin:theme.spacing(1)
    },
  },
  btn: {
    // marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    textTransform: 'capitalize',
  },
  detailsBox: {
    width: '60%',
    marginTop: theme.spacing(30),
    height: theme.spacing(50),
    borderRadius: theme.spacing(1.5),
    paddingLeft: theme.spacing(5),
    // paddingRight: theme.spacing(5),
    background: theme.palette.services.gradientMain,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      // height: '100%',
      // height: theme.spacing(40),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(10),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      alignItems: 'flex-start',
    },
  },
  listbox: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(2),
      // justifyContent: 'center',
    },
  },
  subheading: {
    fontSize: theme.typography.h1.fontSize,
    color: theme.palette.white,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(5),
      fontSize: theme.typography.h1.fontSize,
    },
  },
  description: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.white,
    letterSpacing: theme.spacing(0.1),
    cursor: 'pointer',
    // on hover underline
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    color: theme.palette.contact.grayText,
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.contact.grayText,
  },
}));
