import React, { useState } from 'react';
// import Base64 from 'js-base64';
// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// assets
import Location from '../../../assets/svgs/Contact/Location';
import Email from '../../../assets/svgs/Contact/Email';

// style
import Styles from '../styles/ContactForm.styles';

const Banner = () => {
  const classes = Styles();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault()
    var Base64 = require('js-base64').Base64;
    const tok = 'developers:>;JQwa[zL!EX+f{?3{fjnK';
    const hash = Base64.encode(tok);
    const Basic = 'Basic ' + hash;
    const payload = {
      name, company, message, email
    };

    fetch('https://ria-api-staging.vivpro.ai/v2/email_service/contact', {
      method: 'POST', headers: {
        'Content-type': 'application/json', Authorization: Basic
      }, body: JSON.stringify(payload)
    })
      .then((res) => {
        setName('');
        setEmail('');
        setCompany('');
        setMessage('');
        setSuccess(true);
      }).catch((e) => {
      setErrorMessage('Something went wrong!');
      setError(true);
    });


  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  return (<Box className={classes.root}>
      <Container>
        {error ? (<Snackbar open={error} autoHideDuration={4000} onClose={handleClose}>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>) : null}
        {success ? (<Snackbar
            open={success}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert severity="success">
              Message has been sent successfully!
            </Alert>
          </Snackbar>) : null}
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-evenly"
              className={classes.formContainer}
            >
              <Typography align="left" variant="h2" className={classes.heading}>
                Let's communicate with us what your needs are.
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  value={name}
                  className={classes.textField}
                  onChange={(event) => setName(event.target.value)}
                  fullWidth
                  required
                  label="Your Name"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.label
                  }}
                  InputProps={{
                    disableUnderline: true, classes: { input: classes.input }
                  }}
                />
                <TextField
                  className={classes.textField}
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                  label="Your Email"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.label
                  }}
                  InputProps={{
                    disableUnderline: true, classes: { input: classes.input }
                  }}
                />
                <TextField
                  className={classes.textField}
                  required
                  value={company}
                  onChange={(event) => setCompany(event.target.value)}
                  fullWidth
                  label="Company name"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.label
                  }}
                  InputProps={{
                    disableUnderline: true, classes: { input: classes.input }
                  }}
                />
                <TextField
                  className={classes.textField}
                  required
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  fullWidth
                  multiline
                  rows={5}
                  label="How we can help!"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.label
                  }}
                  InputProps={{
                    disableUnderline: true, classes: { input: classes.input }
                  }}
                />
                <Button
                  type="submit"
                  // onClick={handleSubmit}
                  variant="contained"
                  fullWidth
                  className={classes.btn}
                >
                  Send Message
                </Button>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
              alignItems="flex-start"
              className={classes.detailsBox}
            >
              <Typography className={classes.subheading}>
                Find us here
              </Typography>
              <Typography className={classes.description}>
                <b>Head Office:</b>
              </Typography>
              <Box
                ml={2}
                mt={-3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.listbox}
              >
                <Location />
                <Typography className={classes.description}>
                  Lansdale, PA
                </Typography>
              </Box>
              <Typography className={classes.description}>
                <b>Other locations:</b>
              </Typography>
              <Box
                ml={2}
                mt={-3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.listbox}
              >
                <Location />
                <Typography className={classes.description}>
                  525 Washington Blvd, Jersey City, NJ
                </Typography>
              </Box>
              <Box
                ml={2}
                mt={-3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.listbox}
              >
                <Location />
                <Typography className={classes.description}>
                  Herndon, VA
                </Typography>
              </Box>
              <Box
                mt={-2}
                ml={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.listbox}
              >
                <Location />
                <Typography className={classes.description}>
                  Pune, India
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.listbox}
              >
                <Email />
                <Typography className={classes.description}>
                  sales@vivpro.ai
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>);
};

export default Banner;
