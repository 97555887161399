import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    // marginTop: theme.spacing(15),
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    background: theme.palette.subscription.blue
  },
  description: {
    marginTop: theme.spacing(4),
    color: theme.palette.footer.textLight
  },
  heading: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(2.5),
    color: theme.palette.white,
    fontWeight: 'bold'
  },
  bodyText: {
    color: theme.palette.footer.textDark,
    cursor: 'pointer',
    // on hover underline
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}))