import React from 'react';

// material ui
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

// assets
import VivproLogo from '../assets/svgs/VivproLogo';
import Linkedin from '../assets/svgs/Linkedin';

// styles
import Styles from './styles/Footer.styles';
import { Box } from '@mui/material';
import themev5 from '../themev5';
import theme from '../theme';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const classes = Styles();

  return (
    <Box sx={{
      paddingTop: themev5.spacing(15),
      paddingBottom: themev5.spacing(15),
      background: location.pathname ==='/services'?'#0F0F0F':theme.palette.subscription.blue
    }}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={6}>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <VivproLogo />
              <Typography variant='subtitle1' className={classes.description}>
                A Revolutionary Software Platform helps you to Deliver Your
                Tomorrow
              </Typography>
              <Box mt={4} display='flex' flexDirection='row'>
                <Link
                  href='https://www.linkedin.com/company/vivpro-corp/'
                  underline='none'
                >
                  <IconButton aria-label='Linkedin'>
                    <Linkedin />
                  </IconButton>
                </Link>
                {/* <IconButton>
                  <Facebook />
                </IconButton>
                <IconButton>
                  <Twitter />
                </IconButton> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} lg={2}>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography className={classes.heading}>Menu</Typography>
              <Box
                mt={3}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
              >
                <Link href='/' underline='none'>
                  <Typography align='left' className={classes.bodyText}>
                    Home
                  </Typography>
                </Link>

                <Link href='/products' underline='none'>
                  <Typography align='left' className={classes.bodyText}>
                    Product
                  </Typography>
                </Link>
                <Link href='/services' underline='none'>
                  <Typography align='left' className={classes.bodyText}>
                    Services
                  </Typography>
                </Link>
                <Link href='/company' underline='none'>
                  <Typography align='left' className={classes.bodyText}>
                    About
                  </Typography>
                </Link>
                <Link href='/resources' underline='none'>
                  <Typography align='left' className={classes.bodyText}>
                    Resources
                  </Typography>
                </Link>
                {/* <Typography align='left' className={classes.bodyText}>
                  Newsroom
                </Typography> */}
                <Link href='/contact' underline='none'>
                  <Typography align='left' className={classes.bodyText}>
                    Contact
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={4} lg={2}>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography className={classes.heading}>Documents</Typography>
              <Box
                mt={3}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
              >
                <Typography align='left' className={classes.bodyText}>
                  Whitepaper
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Privacy Policy
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Terms of use
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Agreement
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Cookies
                </Typography>
              </Box>
            </Box>
          </Grid> */}
          <Grid item xs={4} lg={2}>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography className={classes.heading}>Address</Typography>
              <Box
                mt={3}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
              >
                <Typography align='left' className={classes.bodyText}>
                  <b>Head Office:</b>
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Lansdale, PA
                </Typography>
                <br/>
                <Typography align='left' className={classes.bodyText}>
                  <b>Other locations:</b>
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  525 Washington Blvd, Jersey city, NJ
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Herndon, VA
                </Typography>
                <Typography align='left' className={classes.bodyText}>
                  Pune, India
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
